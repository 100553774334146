import React from "react";
import Layout from "../../Components/layout";
import GetItems from "../../Components/getItems";
import { Seo } from "../../Components/seo";


export default function Onesie() {
  return (
    <Layout>
      <GetItems search="Youth T-Shirt" title="Youth T-Shirts" onesie />
    </Layout>
  )
}
export const Head = () => (<Seo pathname={'./kids/ytshirt'} title="STM Quality Youth T-Shirts: Positivity and Awareness" description={"Shopping for Youth T-Shirts to spread positivity and awareness and promote Stronger Than Medicine (STM)."} />)